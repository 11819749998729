import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Link } from "./../util/router";

const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin
  container: {
    padding: `0 ${theme.spacing(3)}px`,
  },
  image: {
    margin: "0 auto",
    maxWidth: 570,
    display: "block",
    height: "auto",
    width: "100%",
  },
  backgroundVideo: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    zIndex: -1, // Ensures the video stays behind everything
  },
  vidSection: {
    position: "relative",
    width: "100%",
    minHeight: "100vh", // Ensure full height
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  }

}));

function HeroSection(props) {
  const classes = useStyles();

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      className={classes.vidSection} // Apply full height style
    >

      {/* Background Video */}
      {props.bgVideo && (
        <video className={classes.backgroundVideo} autoPlay loop muted>
          <source src={props.bgVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
      
     <div>
     <Button
     style={{
      position: "absolute",
      bottom: 100,
      left: "50%",
      transform: "translateX(-50%)",
     }}
        component={Link}
        to={props.buttonPath}
        variant="contained"
        size="large"
        color={props.buttonColor}
      >
        {props.buttonText}
      </Button>
      </div>
      {/* <Container className={classes.container}>
        <Grid container={true} alignItems="center" spacing={6}>
          <Grid container={true} item={true} direction="column" xs={12} md={6}>
            <Box textAlign={{ xs: "center", md: "left" }}>
              <SectionHeader
                title={props.title}
                subtitle={props.subtitle}
                size={4}
              />
              <Button
                style={{position: 'relative', bottom: 90}}
                component={Link}
                to={props.buttonPath}
                variant="contained"
                size="large"
                color={props.buttonColor}
              >
                {props.buttonText}
              </Button>
            </Box>
          </Grid>
          <Grid item={true} xs={12} md={true}>
          </Grid>
        </Grid>
      </Container> */}
    </Section>
  );
}

export default HeroSection;
