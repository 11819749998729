import React, { useState, useEffect, useContext, useRef } from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation, updateLocation, createLocation } from "../util/db";
import { useTheme } from '@material-ui/core/styles';
import Geocode from "react-geocode";
import MapForLocationSelectSearchAutocomplete from "./MapForLocationSelectSearchAutocomplete";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { shootingTypes, prepwrapTypes, productionTypes } from "../util/locationTypes";
import CustomTypeDialogCreate from "./CustomTypeDialogCreate";
import CustomTypeDialogDelete from "./CustomTypeDialogDelete";
import { CategoryContext } from "../providers/CategoryProvider";
import { toast } from 'react-hot-toast';

const useStyles = makeStyles(() => ({
  content: {
    paddingBottom: 24
  },
  buttonBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
    gap: 20
  }
}));

function EditLocationModal(props) {
  const {category, setCategory} = useContext(CategoryContext);
  const classes = useStyles();
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const theme = useTheme();
  const ifSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const { register, handleSubmit, errors, formState, getValues } = useForm();
  const { data: locationData, status: locationStatus, error: locationError } = useLocation(props.id);
  const [mapData, setMapData] = useState(null);
  const [typesList, setTypesList] = useState(
    category === 'shooting' && props.groupCustomTypes ? [...shootingTypes].concat(props.groupCustomTypes)
    : category === 'shooting' && !props.groupCustomTypes ? [...shootingTypes]
    : category === 'prepwrap' && props.groupCustomTypes ? [...prepwrapTypes].concat(props.groupCustomTypes) 
    : category === 'prepwrap' && !props.gorupCustomTypes ? [...prepwrapTypes]
    : category === 'production' && props.groupCustomTypes ? [...productionTypes].concat(props.groupCustomTypes) 
    : category === 'productioni' && !props.groupCustomTypes ? [...productionTypes]
    : []);
  const [type, setType] = useState(props.locationType ? props.locationType : category === 'shooting' ? shootingTypes[0].type : category === 'prepwrap' ? prepwrapTypes[0].type : productionTypes[0].type);
  const [createCustomTypeOpen, setCreateCustomTypeOpen] = useState(false);
  const [deleteCustomTypeOpen, setDeleteCustomTypeOpen] = useState(false);
  const [hasEmptyFields, setHasEmptyFields] = useState(true)
  const {isDirty} = formState;

  // Function to check if any field is empty
  const checkHasEmptyFields = () => {
    const values = getValues();
    console.log(values)
    setHasEmptyFields(Object.values(values).some(value => value === '' || value === undefined));
  };
  console.log('type is ' + type);
  console.log('props type is ' + props.locationType);
  
  // initiate react geocode
  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);

  function getCurrentPosition() {
    // Simple wrapper
    return new Promise((res, rej) => {
        navigator.geolocation.getCurrentPosition(res, rej);
    });
}

// useEffect(() => {
//   alert(type)
// }, [type]);


  // useEffect(() => {
  //   if (!typesList.some(listType => listType['type'] === type)) {
  //     console.log('here.. not in the list! - ' + type)
  //     console.log(JSON.stringify(typesList));
  //     console.log('where here, supposedly the list doesnt contain ' + type + ' .. so now we be settin it to 1')
  //     setType(
  //       category === 'shooting' ? shootingTypes[0].type 
  //       : category === 'prepwrap' ? prepwrapTypes[0].type 
  //       : category === 'production' ? productionTypes[0].type 
  //       : 'INVALID CATEGORY');
  //   } else {
  //     console.log('YO.. IN THE LIST!! - ' + type)
  //   }
  // }, [category, type, typesList]);

  useEffect(() => {
    const updateListWithCustomTypes = () => {
      // clear out custom ones if they were deleted, add new if they were added. keeping it up to date with whats in the db. 
      // if the props locationType that we opened the modal with doesnt exist any longer in teh custom group, lets default to 1st choice in cat.
      setTypesList(
        category === 'shooting' && props.groupCustomTypes ? [...shootingTypes].concat(props.groupCustomTypes)
        : category === 'shooting' && !props.groupCustomTypes ? [...shootingTypes]
        : category === 'prepwrap' && props.groupCustomTypes ? [...prepwrapTypes].concat(props.groupCustomTypes) 
        : category === 'prepwrap' && !props.gorupCustomTypes ? [...prepwrapTypes]
        : category === 'production' && props.groupCustomTypes ? [...productionTypes].concat(props.groupCustomTypes) 
        : category === 'productioni' && !props.groupCustomTypes ? [...productionTypes]
        : []);
    }
    console.log('here')
    //update custom list each time the db group custom types change.
    updateListWithCustomTypes();
  }, [category, props.gorupCustomTypes, props.groupCustomTypes]);

  useEffect(() => {
    if (props.id) {
      setMapData(locationData?.mapData);
    } else {
      getCurrentPosition().then((res) => {
        console.log('success.. set map data from current location! ');
        console.log(res);
        var lat = res.coords.latitude;
        var lng = res.coords.longitude;
        // Get address from latitude & longitude.
        Geocode.fromLatLng(lat, lng).then(
          (response) => {
            const address = response.results[2].formatted_address;
            console.log(address);
            setMapData({ address: address, coords: { lat: lat, lng: lng } });
          },
          (error) => {
            console.error(error);
          }
        );
      }).catch((err) => {
        console.log('error.. there was an issue getting users current location from the browser, set to default hard coded coordinates.')
        console.log(err);
        setMapData({ address: 'Worcester, MA, USA', coords: { lat: 42.2625932, lng: -71.8022934 } });
      }); 
    }
  }, [locationData?.mapData, props.id]);

  // const checkIfSelectedTypeHasBeenDeleted = (deletedTypes) => {
  //   console.log(' TIMOTHY JAMES KONTOS ')
  //   console.log(deletedTypes);
  //   console.log(type)
  //   if (deletedTypes.some(dt => dt.type === type)) {
  //           setType(
  //             category === 'shooting' ? shootingTypes[0].type 
  //             : category === 'prepwrap' ? prepwrapTypes[0].type 
  //             : category === 'production' ? productionTypes[0].type 
  //             : 'INVALID CATEGORY')}
  // };

  // If we are updating an existing item
  // don't show modal until item data is fetched.
  if (props.id && locationStatus !== "success") {
    return null;
  }

  const onSubmit = (data) => {
    setPending(true);
    const typeOrder = typesList.find((list) => list.type === type);
    // update object to save the map/location data!
    var updatedData = {};    
    if (type === "SET" || type === 'PREP / WRAP SET') {
      updatedData = {
      name: data?.name?.toUpperCase(), 
      type: type, 
      typeOrder: props.new ? typeOrder.orderNumber : props.locationTypeOrder, 
      setName: data?.setName?.toUpperCase(), 
      mapData: mapData,
      category: category, 
      groupId: props.groupId}
    } else {
      updatedData = {
        name: data?.name?.toUpperCase(), 
        type: type, 
        typeOrder: props.new ? typeOrder.orderNumber : props.locationTypeOrder, 
        setName: null, 
        mapData: mapData,
        category: category, 
        groupId: props.groupId}
  };
    console.log('updated data.. ' + JSON.stringify(updatedData));
        
    const query = props.id
      ? updateLocation(props.id, updatedData)
          .then((response) => {
            if (response.success) {
              toast.success(response.message);
            } else {
              toast.error(response.message)
            }
          })
          .catch((error) => {
            toast.error(error.message)
          })
      : createLocation({ productionID: props.productionId, ...updatedData })
          .then((response) => {
            if (response.success) {
              toast.success(response.message);
            } else {
              toast.error(response.message)
            }
          })
          .catch((error) => {
            toast.error(error.message)
          });

    query
      .then(() => {
        // Let parent know we're done so they can hide modal
        props.onDone();
      })
      .catch((error) => {
        // Hide pending indicator
        setPending(false);
        // Show error alert message
        setFormAlert({
          type: "error",
          message: error.message,
        });
      });
  };

  return (
    <Dialog 
    disableEnforceFocus
    style={{ pointerEvents: 'none' }}
    PaperProps={{ style: { pointerEvents: 'auto' } }}
    open={true} onClose={props.onDone} fullScreen={ifSmall} fullWidth={true} maxWidth='xl'>
      <DialogTitle className="editHeader">
        {props.id && <>Update</>}
        {!props.id && <>Create</>}
        {` `}Location
      </DialogTitle>

      <DialogContent className={classes.content}>
        {formAlert && (
          <Box mb={4}>
            <Alert severity={formAlert.type}>{formAlert.message}</Alert>
          </Box>
        )}

        {(locationStatus === "loading") && (
          <Box py={5} px={3} align="center">
            {locationStatus === "loading" && <CircularProgress size={32} />}
          </Box>
        )}
        {(locationStatus !== "loading") && (

        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container={true} spacing={3}>

          <Grid item={true} xs={ ifSmall ? 12 : ( type === 'SET' || type === 'PREP / WRAP SET' ? 3 : 6)}>
            <TextField
            variant="outlined"
            id="locationTypeSelect"
            label="Type"
            inputProps={{ style: { textTransform: "uppercase" } }}
            name="type"
            error={errors.type ? true : false}
            select  
            helperText={errors.type && errors.type.message}
            // inputRef={register({
            //   required: "Please enter a type",
            // })}
            fullWidth
            defaultValue={type}
            value={type}
            onChange={(e) => [setType(e.target?.value ? e.target.value : type), checkHasEmptyFields()]}
            >
            {typesList?.sort((a,b) => a?.orderNumber - b?.orderNumber)
            .map(theType => (
            <MenuItem key={theType?.orderNumber} value={theType?.type}>
             {theType?.type} {props.groupCustomTypes?.some(ct => ct.type === theType?.type) && <span style={{fontSize: '.7em', paddingLeft: 4}}>(custom)</span>}
            </MenuItem>
             ))}
             <div className={classes.buttonBar}>
             {props.groupCustomTypes?.length > 0 && <Button variant="contained" color="secondary" onClick={()=>setDeleteCustomTypeOpen(true)}>
              DELETE CUSTOM
             </Button>}
             <Button variant="contained" color="primary" onClick={()=>setCreateCustomTypeOpen(true)}>
              + ADD CUSTOM
              </Button>
             </div >
            </TextField>
          </Grid>
          {(type === 'SET' || type === 'PREP / WRAP SET') && <Grid item={true} xs={ ifSmall ? 12 : ( type === 'SET' || type === 'PREP / WRAP SET' ? 3 : 6)}>
          <TextField
                autoComplete='off'
                variant="outlined"
                type="text"
                label="Set Name"
                name="setName"
                inputProps={{ style: { textTransform: "uppercase" } }}
                defaultValue={locationData && locationData.setName}
                error={errors.setName ? true : false}
                helperText={errors.setName && errors.setName.message}
                fullWidth={true}
                onChange={()=>checkHasEmptyFields()}
                inputRef={register({
                  required: "Please enter a set name",
                })}
              />
          </Grid>}

          <Grid item={true} xs={ ifSmall ? 12 : 6}>
          <TextField
                autoComplete='off'
                variant="outlined"
                type="text"
                label="Description"
                name="name"
                inputProps={{ style: { textTransform: "uppercase" },  }}
                defaultValue={locationData && locationData.name}
                error={errors.name ? true : false}
                helperText={errors.name && errors.name.message}
                fullWidth={true}
                onChange={()=>checkHasEmptyFields()}
                inputRef={register({
                  required: "Description cannot be blank!",
                  // pattern: { value: /^[a-zA-Z0-9 ]+$/, message: 'No special characters!' }
                })}
              />
          </Grid>

            <Grid item={true} xs={12}>
              <div>
                {/* {locationData?.mapData?.address} */}
                <MapForLocationSelectSearchAutocomplete setMapData={setMapData} mapData={mapData ? mapData : locationData?.mapData} />
                {/* <Map setMapData={setMapData} mapData={mapData}/> */}
                </div>
            </Grid>
            
            <Grid item={true} xs={12} className="editFooter">
            <Button
                variant="contained"
                color="secondary"
                size="large"
                onClick={props.onDone}
              >
                Cancel
              </Button>
              {/* if this is a brand new location */}
              {props.new && <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={pending || !isDirty || hasEmptyFields}
              >
                {!pending && <span>Save</span>}

                {pending && <CircularProgress size={28} />}
              </Button>}
              {/* if we are editing an existing location */}
              {!props.new && <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={pending || (!isDirty && locationData?.mapData === mapData && locationData?.type === type) || (isDirty && hasEmptyFields)}
              >
                {!pending && <span>Save</span>}
                {pending && <CircularProgress size={28} />}
              </Button>}

            </Grid>

          </Grid>
        </form>
        )}

      {/* CREATE CUSTOM TYPE */}
      <CustomTypeDialogCreate open={createCustomTypeOpen} setOpen={setCreateCustomTypeOpen} fullTypesList={typesList} customTypes={props.groupCustomTypes} groupId={props.groupId} listLength={typesList?.length} setType={setType} />
      
      {/* DELETE CUSTOM TYPE */}
      <CustomTypeDialogDelete open={deleteCustomTypeOpen} setOpen={setDeleteCustomTypeOpen} groupId={props.groupId} customTypes={props.groupCustomTypes} />

      </DialogContent>
    </Dialog>
  );
}

export default EditLocationModal;
