import React, {useEffect, forwardRef, useContext} from "react";
import Box from "@material-ui/core/Box";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import DatePicker from "react-datepicker";
import { useAuth } from "./../util/auth";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { DateContext } from "../providers/DateProvider";
import { CategoryContext } from '../providers/CategoryProvider';
import styled from 'styled-components';

const useStyles = makeStyles((theme) => ({
  root: {
    // Add bottom margin if element below
    "&:not(:last-child)": {
      marginBottom: "2rem",
    },
  },
  date: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  theDay: {
    width:'400px',
    cursor:'pointer',
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  theDayMobile: {
    cursor:'pointer',
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  datepicker: {
    backgroundColor: 'red'
  }
}));

const DatePickerWrapper = styled.section`
  .react-datepicker {
    background-color: #151824ff;
    border-radius: 5px;
    padding: 10px;
    border-color: #151824ff;
    min-height: 450px;
    }
    .react-datepicker__month-container {
    background-color: #151824ff;
    min-height: 450px;
    }
    .react-datepicker__day {
    color: white;
    line-height: 2.5rem;
    }
    .react-datepicker__day:hover {
    background-color: #0059a6;
    }
    .react-datepicker__day--keyboard-selected {
    background: none;
    }
  
    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range {
    background-color: #e91e63;
  
    }
    .react-datepicker__header {
    background-color: #151824ff;
    border-bottom: 1px solid #0059a6;
    }
    .react-datepicker__current-month {
    color: white;
    }
    .react-datepicker__day-name {
    color: white
    }
    .react-datepicker__today-button {
    background-color: #0059a6;
    color: white;
    padding: 10px;
    border-radius: 5px;
    border: none;
    position: absolute;
    bottom: 10px;
    width: 95%;
    }
    .react-datepicker__day--selected:hover {
      background-color: #e91e63;
    }
    .react-datepicker__navigation {
    top: 20px;
    }
    .react-datepicker__header__dropdown {
    padding-top: 10px
    }
    .react-datepicker__month-select {
      color: white;
      background-color: #151824ff;
      border: none;
    }
    .react-datepicker__year-select {
      color: white;
      background-color: #151824ff;
      border: none;
    }
    .react-datepicker__day--outside-month {
        /* visibility: hidden; */
        opacity: 0.2;
        pointer-events: none; /* to prevent click */
    }
`;

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
  
function DateCarousel(props) {
  const {day, setDay, dayStamp, setDayStamp} = useContext(DateContext)
  const classes = useStyles();
  const theme = useTheme();
  const ifSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const auth = useAuth();
  const {category, setCategory} = useContext(CategoryContext);

  useEffect(() => {
    if (setDay) {
      setDay(day);
      setDayStamp(dayStamp);
    }
  }, [day, dayStamp, setDay, setDayStamp]);

  const prevDay = () => {
    var dateObj = new Date(day);
    const prevDay = new Date(new Date(dateObj).setDate(dateObj.getDate() - 1))
    setDay(prevDay.toDateString());
    setDayStamp(prevDay.getTime());
    if (category === 'production') {
      setCategory('shooting');
    }
  };

  const nextDay = () => {
    var dateObj = new Date(day);
    const nextDay = new Date(new Date(dateObj).setDate(dateObj.getDate() + 1))
    setDay(nextDay.toDateString());
    setDayStamp(nextDay.getTime());
    if (category === 'production') {
      setCategory('shooting');
    }
  };

  function readableDay(separator=', ') {
    let newDate = new Date(day)
    let date = newDate.getDate();
    let theDay = weekday[newDate.getDay()];
    let month = monthNames[newDate.getMonth()]
    let year = newDate.getFullYear();
    return `${theDay}, ${month<10?`0${month}`:`${month}`} ${date}${separator}${year}`
  }

  function mobileDay(separator='/') {
    let newDate = new Date(day)
    const yyyy = newDate.getFullYear();
    let mm = newDate.getMonth() + 1; // month is zero-based
    let dd = newDate.getDate();
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    const formatted = mm + '/' + dd + '/' + yyyy;
    console.log(formatted); // 24/04/2023
    return formatted;
  }

  const DateTitlePickerInput = forwardRef(({ value, onClick }, ref) => (
      <div className={ifSmall ? classes.theDayMobile : classes.theDay} onClick={onClick} ref={ref}>
         {ifSmall ? mobileDay() : readableDay() } {"    "}
         <DateRangeIcon style={{marginLeft: 5}} color="secondary" />
      </div>
  ));

  return (
    <Box className={classes.root + (props.className ? ` ${props.className}` : "")}>
      <Typography variant="h5">
        {auth.user.planIsActive && <div className={classes.date}>
          <Button onClick={prevDay}><ChevronLeftIcon fontSize="large" color="secondary"/></Button>
          <DatePickerWrapper>
           <DatePicker
            style={{ display: 'none' }}
            selected={new Date(day)}
            onChange={(date) => {
              setDay(date.toDateString());
              setDayStamp(date.getTime());
              if (category === 'production') {
                setCategory('shooting');
              }
            }}
            customInput={<DateTitlePickerInput />}
            todayButton="Today"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            withPortal
            /></DatePickerWrapper>
          <Button onClick={nextDay}><ChevronRightIcon fontSize="large" color="secondary"/></Button>
          </div>}
        </Typography>
    </Box>
  );
}

export default DateCarousel;
