import React from "react";
import DateCarousel from "./DateCarousel";
import LocationGroupList from "./LocationGroupList";
import LocationFilterBar from "./LocationFilterBar";

function DashboardLocations(props) {

  return (
    <>              
      <DateCarousel
          size={4}
          textAlign="center"
        />
    <LocationFilterBar /> 
    <LocationGroupList productionId={props.productionId} />
    </>
  );
}

export default DashboardLocations;
 