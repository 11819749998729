import React, { useState, useContext, forwardRef } from "react";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import LocationOn from "@material-ui/icons/LocationOn";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import MovieCreation from "@material-ui/icons/MovieCreation";
import EditLocationModal from "./EditLocationModal";
import LocalHospital from "@material-ui/icons/LocalHospital";
import HouseIcon from '@material-ui/icons/House';
import BuildIcon from '@material-ui/icons/Build';
import PeopleIcon from '@material-ui/icons/People';
import Restaurant from "@material-ui/icons/Restaurant";
import HotelIcon from '@material-ui/icons/Hotel';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import MovieFilterIcon from '@material-ui/icons/MovieFilter';
import WcIcon from '@material-ui/icons/Wc';
import LocalParking from "@material-ui/icons/LocalParking";
import BusinessIcon from '@material-ui/icons/Business';
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { useTheme } from '@material-ui/core/styles';
import DeleteIcon from "@material-ui/icons/Delete";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import TheatersIcon from '@material-ui/icons/Theaters';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import DirectionsIcon from '@material-ui/icons/Directions';
import { deleteLocation, useLocationsByGroupId } from "../util/db";
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from "@material-ui/core/styles";
import CopyLocationSelectGroupDialog from "./CopyLocationSelectGroupDialog";
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { CategoryContext } from "../providers/CategoryProvider";
import DatePicker from "react-datepicker";
import { toast } from 'react-hot-toast';

const useStyles = makeStyles((theme) => ({
    listItemWidth: {
      [theme.breakpoints.down("sm")]: {
        width: "85%",
      }
    },
    locationIcon: {
      verticalAlign: "bottom",
      minWidth: "20px !important"
    },
    mainIcons: {
      verticalAlign: "bottom",
      minWidth: "20px !important",
      marginRight: "10px",
    },
    iconsByGroup: {
      color: 'red'
    },
    mobileCard: {
      paddingBottom: '15px'
    },
    cardHeader: {
      paddingBottom: '10px',
    },
    cardContent: {
      paddingTop: '0px !important',
      paddingBottom: '0px !important',
      // paddingLeft: '72px !important',
      // textAlign: 'center',
      color: 'white'
    },
    cardTitle: {
      fontSize: '18px'
    },
    cardSubheader: {
      paddingTop: '5px',
      fontSize: '14px',
      color: 'lightGray'
    }
  }));

function LocationListItem(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [creatingLocation, setCreatingLocation] = useState(false);
    const [directionsDestination, setDirectionsDestination] = useState({});
    const [setName, setSetName] = useState('');
    const [generatingPDF, setGeneratingPDF] = useState(false);
    const [updatingLocationId, setUpdatingLocationId] = useState(null);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [localToDelete, setLocalToDelete] = useState({});
    const ifSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const [anchorElMenu, setAnchorElMenu] = useState(null);
    const [pickGroupToCopyToOpen, setPickGroupToCopyToOpen] = useState(false);
    const [copyDay, setCopyDay] = useState(null)
    const {category, setCategory} = useContext(CategoryContext)

    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      
    } = useSortable({id: props.location.id,
      animateLayoutChanges: () => false
  
    });
  
    const sortableStyle = {
      transform: CSS.Translate.toString(transform),
      transition
    }

    const handleCloseConfirm = () => {
        setConfirmDeleteOpen(false);
        setLocalToDelete({});
        handleCloseLocationActionMenu();
    };

    const handleDeleteAndClose = () => {
        setConfirmDeleteOpen(false);
        deleteLocation(localToDelete.id)
          .then((response) => {
            if (response.success) {
              toast.success(response.message);
            } else {
              toast.error(response.message)
            }
          })
          .catch((error) => {
            toast.error(error.message)
          });
        setLocalToDelete({});
        handleCloseLocationActionMenu();
    }

    const handleEditLocationModalClose = () => {
        setUpdatingLocationId(null);
        handleCloseLocationActionMenu()
    }

    const handleClickLocationActionMenu = (event) => {
      setAnchorElMenu(event.currentTarget);
    }
  
    const handleCloseLocationActionMenu = () => {
      setAnchorElMenu(null);
    }

    const initiatePDFModal = (dest, set) => {
        setDirectionsDestination(dest);
        setSetName(set);
        setGeneratingPDF(true);
    }

    const typeContainsWords = (possibleWords, type) => {
      if (possibleWords.some(v => type.includes(v))) {
        // There's at least one
        return true;
      }
    }

    const CopyLocationDatePickerInput = forwardRef(({ value, onClick }, ref) => (
        <div onClick={onClick} ref={ref} >
          <IconButton
          edge="end"
          aria-label="update"
          >
          <FileCopyIcon />
          </IconButton>
        </div>
      ));

    const CopyLocationDatePickerInputMobile = forwardRef(({ value, onClick }, ref) => (
      <div onClick={onClick} ref={ref}>
      <MenuItem >
      <ListItemIcon>
        <FileCopyIcon fontSize="medium" />
      </ListItemIcon>
        Copy Location
        </MenuItem>
        </div>
  
    ));

return (
        <>
        {/* for desktop */}
        {!ifSmall && 
        <div ref={setNodeRef} style={sortableStyle} id="customPortal">
        <ListItem
        style={{ backgroundColor: '#22273eff' }}
        key={props.index}
        divider={props.index !== props.locationLength - 1}
        className="dashboardItemContainer"
        >
        <ListItemText className={classes.listItem}>
            {props.location?.type.toLowerCase().includes('set') && <ListItemIcon style={{color: props.groupColor}} className={classes.mainIcons}><MovieCreation  /> </ListItemIcon>}
            {props.location?.type.toLowerCase().includes('parking') && <ListItemIcon style={{color: props.groupColor}} className={classes.mainIcons}><LocalParking /> </ListItemIcon>}
            {(props.location?.type.toLowerCase().includes('cater') || props.location?.type.toLowerCase().includes('breakfast') || props.location?.type.toLowerCase().includes('lunch') || props.location?.type.toLowerCase().includes('food')) && <ListItemIcon style={{color: props.groupColor}} className={classes.mainIcons}><Restaurant /> </ListItemIcon>}
            {(props.location?.type.toLowerCase().includes('hospital') || props.location?.type.toLowerCase().includes('emergency')) && <ListItemIcon style={{color: props.groupColor}} className={classes.mainIcons}><LocalHospital /> </ListItemIcon>}
            {(props.location?.type.toLowerCase().includes('base') || props.location?.type.toLowerCase().includes('camp')) && <ListItemIcon style={{color: props.groupColor}} className={classes.mainIcons}><HouseIcon /> </ListItemIcon>}
            {(props.location?.type.toLowerCase().includes('truck') || props.location?.type.toLowerCase().includes('shipping')) && <ListItemIcon style={{color: props.groupColor}} className={classes.mainIcons}><LocalShippingIcon /> </ListItemIcon>}
            {props.location?.type.toLowerCase().includes('stage') && <ListItemIcon style={{color: props.groupColor}} className={classes.mainIcons}><TheatersIcon  /> </ListItemIcon>}
            {props.location?.type.toLowerCase().includes('office') && <ListItemIcon style={{color: props.groupColor}} className={classes.mainIcons}><BusinessIcon  /> </ListItemIcon>}
            {props.location?.type.toLowerCase().includes('hotel') && <ListItemIcon style={{color: props.groupColor}} className={classes.mainIcons}><HotelIcon  /> </ListItemIcon>}
            {props.location?.type.toLowerCase().includes('transpo') && <ListItemIcon style={{color: props.groupColor}} className={classes.mainIcons}><AirportShuttleIcon  /> </ListItemIcon>}
            {(props.location?.type.toLowerCase().includes('special') || props.location?.type.toLowerCase().includes('spfx')) && <ListItemIcon style={{color: props.groupColor}} className={classes.mainIcons}><MovieFilterIcon  /> </ListItemIcon>}
            {props.location?.type.toLowerCase().includes('construct') && <ListItemIcon style={{color: props.groupColor}} className={classes.mainIcons}><BuildIcon  /> </ListItemIcon>}
            {props.location?.type.toLowerCase().includes('wardrobe') && <ListItemIcon style={{color: props.groupColor}} className={classes.mainIcons}><WcIcon  /> </ListItemIcon>}
            {props.location?.type.toLowerCase().includes('extra') && !props.location?.type.toLowerCase().includes('parking') && <ListItemIcon style={{color: props.groupColor}} className={classes.mainIcons}><PeopleIcon  /> </ListItemIcon>}

            {!typeContainsWords(['set', 'crew', 'parking', 'work trucks', 'cater', 'hospital', 'crew hotel', 'base', 'camp', 'extras', 'emergency', 'shelter', 'truck', 'stage', 'office', 'hotel', 'transpo', 'warehouse', 'construction', 'mill', 'special', 'wardrobe', 'parking', 'breakfast', 'emergency', 'camp', 'shipping', 'spfx', 'workshop', 'food', 'lunch'], props.location?.type.toLowerCase()) && 
            <ListItemIcon style={{color: props.groupColor}} className={classes.mainIcons}><DirectionsIcon  /> </ListItemIcon>}

            <span style={{fontWeight: 700}}>{props.location?.type} {props.location?.setName && ` - "${props.location?.setName}"`}</span>
        </ListItemText>

        <ListItemText>
            {props.location?.name.toUpperCase()} 
        </ListItemText>

        <ListItemText className={classes.listItemWidth}><ListItemIcon style={{color: props.groupColor}} className={classes.locationIcon}><LocationOn /></ListItemIcon>{props.location?.mapData?.address}
        </ListItemText>

        <ListItemSecondaryAction
        style={{ flexDirection: 'row', display: 'flex', zIndex:"999" }} 
        >
            <IconButton
            edge="end"
            aria-label="update"
            onClick={() => setUpdatingLocationId(props.location.id)}
            >
            <EditIcon />
            </IconButton>

            {category !== 'production' ? 
            <DatePicker
            selected={new Date(props.day)}
            allowSameDay
            onChange={(date) => [setCopyDay(date.toDateString(),setPickGroupToCopyToOpen(true))]}
            customInput={<CopyLocationDatePickerInput />}
            todayButton="Today"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            withPortal
            portalId="root-portal"
         >
            <div style={{position: 'absolute', top: '10px', left: '10px', color: 'white', marginLeft: 'auto !important', width: '90%'}}>SELECT A DATE TO COPY THE LOCATION TO</div>
            </DatePicker>
          : 
               <IconButton
               edge="end"
               aria-label="update"
               onClick={() => setPickGroupToCopyToOpen(true)}
               >
               <FileCopyIcon />
               </IconButton>
               }

            <IconButton
            edge="end"
            aria-label="delete"
            onClick={() => {
                setLocalToDelete({name: props.location.name, id: props.location.id, address: props.location?.mapData?.address})
                setConfirmDeleteOpen(true)
            }}
            >
            <DeleteIcon />
            </IconButton>

            <IconButton
            edge="end"
            aria-label="drag"
            style={{ backgroundColor: 'transparent' }}
            >
            {props.numberOfLocationsInList > 1 && <div {...attributes} {...listeners}><DragIndicatorIcon style={{verticalAlign: "middle", cursor: 'grab'}}  /></div>}
            </IconButton>
            
        </ListItemSecondaryAction>
        </ListItem>
        </div>}

        {/* for mobile */}
        {ifSmall &&
        <Card className={classes.mobileCard} variant="outlined">
        <CardHeader
        className={classes.cardHeader}
        key={props.index}
        avatar={
        <Avatar aria-label="recipe" style={{backgroundColor: '#151824ff'}}>
            {props.location?.type.toLowerCase().includes('set') && <MovieCreation style={{color: props.groupColor}}/> }
            {props.location?.type.toLowerCase().includes('parking') && <LocalParking style={{color: props.groupColor}} />}
            {(props.location?.type.toLowerCase().includes('cater') || props.location?.type.toLowerCase().includes('breakfast') || props.location?.type.toLowerCase().includes('lunch') || props.location?.type.toLowerCase().includes('food')) && <Restaurant style={{color: props.groupColor}}/>}
            {(props.location?.type.toLowerCase().includes('hospital') || props.location?.type.toLowerCase().includes('emergency')) && <LocalHospital style={{color: props.groupColor}}/>}
            {(props.location?.type.toLowerCase().includes('base') || props.location?.type.toLowerCase().includes('camp')) && <HouseIcon style={{color: props.groupColor}}/>}
            {(props.location?.type.toLowerCase().includes('truck') || props.location?.type.toLowerCase().includes('shipping')) && <LocalShippingIcon style={{color: props.groupColor}}/>}
            {props.location?.type.toLowerCase().includes('stage') && <ListItemIcon style={{color: props.groupColor}} className={classes.mainIcons}><TheatersIcon /></ListItemIcon>}
            {props.location?.type.toLowerCase().includes('office') && <ListItemIcon style={{color: props.groupColor}} className={classes.mainIcons}><BusinessIcon /></ListItemIcon>}
            {props.location?.type.toLowerCase().includes('hotel') && <ListItemIcon style={{color: props.groupColor}} className={classes.mainIcons}><HotelIcon /></ListItemIcon>}
            {props.location?.type.toLowerCase().includes('transpo') && <ListItemIcon style={{color: props.groupColor}} className={classes.mainIcons}><AirportShuttleIcon  /></ListItemIcon>}
            {(props.location?.type.toLowerCase().includes('special') || props.location?.type.toLowerCase().includes('spfx')) && <ListItemIcon style={{color: props.groupColor}} className={classes.mainIcons}><MovieFilterIcon  /></ListItemIcon>}
            {props.location?.type.toLowerCase().includes('construct') && <ListItemIcon style={{color: props.groupColor}} className={classes.mainIcons}><BuildIcon /></ListItemIcon>}
            {props.location?.type.toLowerCase().includes('wardrobe') && <ListItemIcon style={{color: props.groupColor}} className={classes.mainIcons}><WcIcon /></ListItemIcon>}
            {props.location?.type.toLowerCase().includes('extra') && !props.location?.type.toLowerCase().includes('parking') && <ListItemIcon style={{color: props.groupColor}} className={classes.mainIcons}><PeopleIcon  /> </ListItemIcon>}
            {!typeContainsWords(['set', 'crew', 'parking', 'work trucks', 'cater', 'hospital', 'crew hotel', 'base', 'camp', 'extras', 'emergency', 'shelter', 'truck', 'stage', 'office', 'hotel', 'transpo', 'warehouse', 'construction', 'mill', 'special', 'wardrobe', 'parking', 'breakfast', 'emergency', 'camp', 'shipping', 'spfx', 'workshop', 'food', 'lunch'], props.location?.type.toLowerCase()) && 
            <ListItemIcon style={{color: props.groupColor}} className={classes.mainIcons}><DirectionsIcon  /> </ListItemIcon>}
            </Avatar>
        }
        action={
        <>
        <IconButton aria-label="settings" onClick={handleClickLocationActionMenu}>
            <MoreVertIcon />
        </IconButton>
                        <Menu
                        id="simple-menu"
                        anchorEl={anchorElMenu}
                        keepMounted
                        open={Boolean(anchorElMenu)}
                        onClose={handleCloseLocationActionMenu}
                    >
                        <MenuItem onClick={()=>setUpdatingLocationId(props.location.id)}>
                        <ListItemIcon>
                        <EditIcon fontSize="medium" />
                        </ListItemIcon>
                        Edit Location
                        </MenuItem>


                        {category !== 'production' ? 
                        <DatePicker
                        selected={new Date(props.day)}
                        allowSameDay
                        onChange={(date) => [setCopyDay(date.toDateString(),setPickGroupToCopyToOpen(true))]}
                        customInput={<CopyLocationDatePickerInputMobile />}
                        todayButton="Today"
                        portalId="root-portal"
                        withPortal
                    >
                      <div style={{position: 'absolute', top: '10px', left: '10px', color: 'white', marginLeft: 'auto !important', width: '90%'}}>SELECT A DATE TO COPY THE LOCATION TO</div>
                      </DatePicker>
                      : 
                      <MenuItem
                      onClick={() => {setPickGroupToCopyToOpen(true)}}
                      >
                      <ListItemIcon>
                      <DeleteIcon fontSize="medium" />
                      </ListItemIcon>
                      Copy Location
                      </MenuItem>
                          }

                        <MenuItem
                        onClick={() => {
                            setLocalToDelete({name: props.location.name, id: props.location.id, address: props.location?.mapData?.address})
                            setConfirmDeleteOpen(true)
                        }}
                        >
                        <ListItemIcon>
                        <DeleteIcon fontSize="medium" />
                        </ListItemIcon>
                        Delete Location
                        </MenuItem>

                        {props.location?.type.toLowerCase().includes('crew parking') && <MenuItem disabled onClick={()=>initiatePDFModal({addy: props.location?.mapData?.address, lat: props.location?.mapData.coords.lat, lng: props.location?.mapData.coords.lng}, props.location?.name)}>
                        <ListItemIcon>
                        <PictureAsPdfIcon fontSize="medium" />
                        </ListItemIcon>
                        Generate PDF
                        </MenuItem>}

                    </Menu>
                    </>
        }
        title={<span className={classes.cardTitle} >{props.location?.type} {props.location?.setName && ` - "${props.location?.setName}"`}</span>}
        subheader={<Typography className={classes.cardSubheader}>{props.location?.name.toUpperCase()}</Typography>}
        />
      <CardContent className={classes.cardContent}>
        <Typography variant="body2">
        {props.location?.mapData?.address}
        </Typography>
      </CardContent>
    </Card>    
    }

    {updatingLocationId && 
    <EditLocationModal
        productionId={props.productionId}
        groupId={props.groupId}
        groupCustomTypes={props.groupCustomTypes}
        id={updatingLocationId}
        day={props.day}
        onDone={handleEditLocationModalClose}
        locationType={props.location.type}
        locationTypeOrder={props.location.typeOrder}
        new={false}
    />}

      <Dialog
        //   fullScreen={fullScreen}
          open={confirmDeleteOpen}
          onClose={handleCloseConfirm}
          aria-labelledby="responsive-dialog-title"
      >
      <DialogTitle id="responsive-dialog-title">Are you sure you want to delete this location?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {localToDelete.name}<br/> {localToDelete.address}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCloseConfirm} color="primary">
          Cancel
        </Button>
        <Button onClick={handleDeleteAndClose} color="primary" autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>

    {/* WHICH GROUP TO COPY TO DIALOG */}
    {pickGroupToCopyToOpen && <CopyLocationSelectGroupDialog open={pickGroupToCopyToOpen} setOpen={setPickGroupToCopyToOpen} listOfGroupsAndDays={props.listOfGroupsAndDays} copyDay={copyDay} category={category} location={props.location}/>}

      </>
    )
}

export default LocationListItem;
